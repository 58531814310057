require('./bootstrap');

(function () {
  'use strict'

	// js here

	$(window).on('load', function() {

		// Global Variables
		var $pageHeight = $(window).height(),
				$pageWidth = $(window).width(),
				$headerHeight = $('header#header').outerHeight(),
				$footerHeight = $('footer#footer').outerHeight();
		mainContainer($pageHeight, $headerHeight, $footerHeight);
		mobileMenu();

		$(window).scroll(function() {
		  if ($(this).scrollTop() > 0) {
		    $('header#header').addClass('scrolled');
		  } else {
		    $('header#header').removeClass('scrolled');
		  }
		});


		$('table.sortable > thead > tr > th:not(.nah)').each(function (col) {
      $(this).click(function () {
      	let sortOrder = 1;
        if ($(this).is('.asc')) {
            $(this).removeClass('asc');
            $(this).addClass('desc selected');
            sortOrder = -1;
        } else {
            $(this).addClass('asc selected');
            $(this).removeClass('desc');
            sortOrder = 1;
        }
        $(this).siblings().removeClass('asc selected');
        $(this).siblings().removeClass('desc selected');
        var arrData = $('table.sortable').find('tbody:not(.nah-js-sort) >tr:has(td)').get();
        if (arrData && arrData.length) {
	        arrData.sort(function (a, b) {
	            var val1 = $(a).children('td').eq(col).text().toUpperCase();
	            var val2 = $(b).children('td').eq(col).text().toUpperCase();
	            if ($.isNumeric(val1) && $.isNumeric(val2))
	              return sortOrder == 1 ? val1 - val2 : val2 - val1;
	            else
	              return (val1 < val2) ? -sortOrder : (val1 > val2) ? sortOrder : 0;
	        });
	        $.each(arrData, function (index, row) {
	        	$('tbody').append(row);
	        });
        }
      });
	  });

	});

	function mainContainer( $pageHeight = 0, $headerHeight = 0, $footerHeight = 0 ){
		$('#main').css({
			'min-height': $pageHeight + $headerHeight,
			'padding-bottom': $footerHeight,
			'padding-top': $headerHeight
		});
		$('#mobile-menu').css({
			'padding-top': $headerHeight
		});
		$(window).on('resize', function(){
			setTimeout(function(){
				$('#main').css({
					'min-height': $pageHeight + $headerHeight,
					'padding-bottom': $footerHeight,
					'padding-top': $headerHeight
				});
				$('#mobile-menu').css({
					'padding-top': $headerHeight
				});
			}, 200);
		});
	}

	function mobileMenu(){
		$(document).on('click', '#mobile-nav', function(){
			$(this).toggleClass('active');
			$('#main').toggleClass('menu-open');
			$('#mobile-menu').toggleClass('active');
			$('#aside-backdrop').toggleClass('active');
		});
		$(document).on('click', '#aside-backdrop', function(){
			$(this).toggleClass('active');
			$('#main').toggleClass('menu-open');
			$('#mobile-menu').toggleClass('active');
			$('#mobile-nav').toggleClass('active');
		});
		$(document).on('click', '#mobile-menu #mobile ul li.has-submenu a', function(e){
			e.preventDefault();
			var text = $(this).text();
			$('#mobile-menu #mobile ul li').removeClass('active');
			$(this).parent().addClass('active').find('> .sub-menu').addClass('active').find('> .bck-menu .text').text(text);
		});
		if( $('#mobile-menu #mobile ul li').hasClass('has-submenu') ) {
			$('#mobile-menu #mobile ul li.has-submenu > .sub-menu').prepend('<li class="bck-menu"><i class="fal fa-angle-left"></i><span class="text">Back</span></li>')
		}
		$(document).on('click', '#mobile-menu .bck-menu', function(e){
			$(this).parent().removeClass('active');
		});
	}

})()